import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
  FaChevronLeft,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/gavin_walters_panda-01.svg";
import Resume from "../assets/GavinWalters_Resume_2023.pdf";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const touchSupported = window.matchMedia("(pointer: coarse)").matches;
  const [isOpen, setIsOpen] = useState(false);

  const src = document.querySelectorAll(".touchable");
  const handleTap = (e) => {
    const openTab = document.querySelectorAll("li.open");

    console.log("open tabs", openTab.length);

    e.stopPropagation();
    if (touchSupported) {
      setIsOpen(!isOpen);
      console.log("touchable divs", src.length);
      console.log("parent node", e.currentTarget.parentNode);
      // src.forEach((element) => {});
      if (openTab.length < 1) {
        console.log("open tabs", openTab);
        if (!isOpen) {
          e.currentTarget.parentNode.classList.add("open");
          e.currentTarget.parentNode
            .querySelector(".arrow")
            .classList.remove("hidden");
        }
      } else if (openTab[0] == e.currentTarget.parentNode) {
        console.log("This is the open Tab");
        if (isOpen) {
          e.currentTarget.parentNode.classList.remove("open");
          e.currentTarget.parentElement
            .querySelector(".arrow")
            .classList.add("hidden");
        }
      } else {
        // alert("THERES AN OPEN TAB");
        console.log("THIS IS OPEN", openTab);
        console.log(
          "Open parent node",
          openTab[0],
          "Arrow query",
          openTab[0].querySelector(".arrow")
        );
        openTab[0].classList.remove("open");
        openTab[0].querySelector(".arrow").classList.add("hidden");
      }

      // }
      // else {
      //   const openElmt = document.getElementsByClassName("open");

      //   openElmt.classList.remove("open").add("hidden");
    }
  };
  // src.forEach((element) => {
  //   element.addEventListener("touchstart", handleTap, false);
  // });

  return (
    <div className="fixed w-full h-[80px] sm:h-[100px] z-20 md:h-[150px] 2xl:h-[80px] flex justify-between items-center px-4 bg-[#07080C] text-white-smoke">
      <div>
        <img
          src={Logo}
          alt="Logo"
          style={{ width: "500px" }}
          className="mt-0 max-w-[100px] sm:max-w-[150px] sm:mt-[0] md:max-w-[200px] 2xl:max-w-[300px] 2xl:mt-[100px]"
        />
      </div>
      {/* Menu */}
      <ul className="hidden md:flex">
        <li>
          {" "}
          <Link
            activeClass="active"
            to="home"
            smooth={true}
            duration={500}
            spy={true}
          >
            Home
          </Link>
        </li>
        <li>
          {" "}
          <Link
            activeClass="active"
            to="about"
            smooth={true}
            duration={500}
            spy={true}
          >
            About
          </Link>
        </li>
        <li>
          {" "}
          <Link
            activeClass="active"
            to="skills"
            smooth={true}
            duration={500}
            spy={true}
          >
            Skills
          </Link>
        </li>
        <li>
          {" "}
          <Link
            activeClass="active"
            to="work"
            smooth={true}
            duration={500}
            spy={true}
          >
            Work
          </Link>
        </li>
        <li>
          {" "}
          <Link
            activeClass="active"
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#07080C] flex flex-col justify-center items-center mobile-nav z-99999"
        }
      >
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="work" smooth={true} duration={500}>
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* social icons */}
      <div className=" hidden socials lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          {/* hover:ml-[-10px] focus-within:ml-[-10px] */}
          <li
            id="1"
            className="touchable w-[160px] h-[60px] flex justify-between items-center ml-[-100px]   duration-300 bg-[#0A66C2] pr-0"
          >
            <a
              className="flex justify-between items-center w-100px text-gray-300"
              href="https://www.linkedin.com/in/gavin-walters-793911b1/"
              target="_blank"
            >
              LinkedIn
            </a>
            <span
              onTouchStart={handleTap}
              className="flex h-full w-60px pr-[16px] items-center"
            >
              <FaLinkedin size={30} />{" "}
              <FaChevronLeft className="arrow hidden" size={20} />
            </span>
          </li>
          <li
            id="2"
            className="touchable w-[160px] h-[60px] flex justify-between items-center ml-[-100px]   duration-300 bg-[#8e44ad] pr-0"
          >
            <a
              className="flex justify-between items-center w-100px text-gray-300"
              href="https://github.com/gavinwalters"
              target="_blank"
            >
              GitHub
            </a>
            <span
              onTouchStart={handleTap}
              className="flex h-full w-60px pr-[16px] items-center"
            >
              <FaGithub size={30} />
              <FaChevronLeft className="arrow hidden" size={20} />
            </span>
          </li>
          <li
            id="3"
            className="touchable w-[160px] h-[60px] flex justify-between items-center ml-[-100px]  duration-300 bg-[#B23121] pr-0"
          >
            <a
              className="flex justify-between items-center w-100px text-gray-300"
              href="mailto:talk@gavinwalters.ca"
            >
              Email
            </a>
            <span
              onTouchStart={handleTap}
              className="flex h-full w-60px pr-[16px] items-center"
            >
              <HiOutlineMail size={30} />
              <FaChevronLeft className="arrow hidden" size={20} />
            </span>
          </li>
          <li
            id="4"
            className="touchable w-[160px] h-[60px] flex justify-between items-center ml-[-100px]  duration-300 bg-[#F4CBB2] pr-0"
          >
            <a
              className="flex justify-between items-center w-100px text-gray-900"
              href={Resume}
              download="GavinWalters_Resume"
            >
              Resume
            </a>
            <span
              onTouchStart={handleTap}
              className="flex h-full w-60px pr-[16px] items-center"
            >
              <BsFillPersonLinesFill size={30} />
              <FaChevronLeft className="arrow hidden" size={20} />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
