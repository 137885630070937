import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="section w-full h-screen bg-[#07080C] snap-start snap-always"
    >
      {/* container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-white-smoke">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-salmon">
          Gavin Walters
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-ebony">
          I'm a full stack developer.
        </h2>
        <p className="text-[#F2F4F3] py-4 max-w-[700px]">
          I'm a full-stack developer with a strong foundation in web
          development, complemented by certifications in cloud development and
          machine learning. I excel in crafting dynamic and responsive
          full-stack web applications, combining technical expertise with a
          flair for designing captivating digital solutions.
        </p>
        <div>
          <button className="text-white-smoke group border-2 px-6 py-3 my-2 flex items-center hover:bg-yellow-green hover:border-yellow-green hover:text-off-black">
            {" "}
            <Link to="work" smooth={true} duration={500}>
              View Work
            </Link>
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight className="ml-3" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
