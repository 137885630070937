import React from "react";

export const Contact = () => {
  return (
    <div
      name="contact"
      className="section w-full h-screen   bg-off-black flex justify-center items-center p-4 snap-start snap-always"
    >
      <form
        action="https://getform.io/f/ad37b778-7a2e-405a-9627-11f4d76654c2"
        method="POST"
        className="flex flex-col max-w-[600px] w-full "
      >
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-salmon text-white-smoke">
            Contact
          </p>
          <p className="text-white-smoke py-4">
            // Submit the form below or shoot me an email -
            <a className="text-yellow-green" href="mailto:talk@gavinwalters.ca">
              talk@gavinwalters.ca
            </a>
          </p>
        </div>
        <div class="relative z-0 pb-8">
          <input
            type="text"
            name="name"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-ebony appearance-none dark:text-white dark:border-ebony dark:focus:border-salmon focus:outline-none focus:ring-0 focus:border-salmon peer"
            placeholder=" "
            required
          />
          <label
            for="name"
            class="absolute text-sm text-ebony dark:text-ebony duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:salmon peer-focus:dark:text-salmon peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Your Name
          </label>
        </div>
        <div class="relative z-0 pb-8">
          <input
            type="text"
            name="email"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-ebony appearance-none dark:text-white dark:border-ebony dark:focus:border-salmon focus:outline-none focus:ring-0 focus:border-salmon peer"
            placeholder=" "
            required
          />
          <label
            for="email"
            class="absolute text-sm text-ebony dark:text-ebony duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:salmon peer-focus:dark:text-salmon peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Email Address
          </label>
        </div>
        <div class="relative z-0">
          <textarea
            type="text"
            name="message"
            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-ebony appearance-none dark:text-white dark:border-ebony dark:focus:border-salmon focus:outline-none focus:ring-0 focus:border-salmon peer"
            placeholder=" "
            rows="10"
            required
          />
          <label
            for="message"
            class="absolute text-sm text-ebony dark:text-ebony duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:salmon peer-focus:dark:text-salmon peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            What's on your mind?
          </label>
        </div>
        <button
          type="submit"
          className="text-white-smoke border-2 hover:bg-salmon hover:border-salmon focus:outline-none focus:bg-salmon focus:border-salmon px-6 py-3 my-8 mx-auto flex items-center"
        >
          Let's Talk!
        </button>
      </form>
    </div>
  );
};

export default Contact;
