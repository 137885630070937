import React from "react";

import HTML from "../assets/HTML5_Badge.svg";
import CSS from "../assets/CSS3_logo.svg";
import JavaScript from "../assets/Unofficial_JavaScript_logo_2.svg";
import PHP from "../assets/PHP-logo.svg";
import Python from "../assets/python-logo-only.png";
import Solidity from "../assets/Solidity_logo.svg";
import Java from "../assets/java-icon.svg";
import CSharp from "../assets/Logo_C_sharp.svg";

const Skills = () => {
  return (
    <div
      id="skills"
      name="skills"
      className="section w-full h-screen lg:h-screen bg-off-black text-white-smoke snap-start snap-always"
    >
      {/* container */}
      <div
        id="container"
        className="max-w-[1000px]  mx-auto p-4 flex flex-col justify-center w-full h-full"
      >
        <div className="pb-8">
          <h1 className="text-4xl font-bold border-b-4 border-salmon ">
            Skills
          </h1>
          <p className="py-4">// These are the technologies I've worked with</p>
        </div>

        <div className="w-full h-fit grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={HTML}
                alt="HTML icon"
              />
              <p className="my-4">HTML</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={CSS}
                alt="HTML icon"
              />
              <p className="my-4">CSS</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={JavaScript}
                alt="HTML icon"
              />
              <p className="my-4">JAVASCRIPT</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={Python}
                alt="HTML icon"
              />
              <p className="my-4">PYTHON</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon flex items-center justify-center">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={PHP}
                alt="HTML icon"
              />
              <p className="my-4">PHP</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={Solidity}
                alt="HTML icon"
              />
              <p className="my-4">SOLIDITY</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={Java}
                alt="HTML icon"
              />
              <p className="my-4">JAVA</p>
            </div>
          </div>
          <div className="shadow-md shadow-salmon bg-salmon">
            <div className=" hover:scale-110 duration-500">
              <img
                className="sm:w-20 w-8 mx-auto pt-4"
                src={CSharp}
                alt="HTML icon"
              />
              <p className="my-4">C#/.NET</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
